import { FetchUserAttributesOutput, fetchUserAttributes, signIn as signInFromAws } from 'aws-amplify/auth'
import useIsMounted from 'ismounted'
import { useRouter } from 'next-nprogress-bar'
import { useState } from 'react'

import { analytics } from '../utils/analytics'
import { authenticatedPaths, unauthenticatedPaths } from '../utils/route-utils'
import { sanitizePassword, sanitizeUsername } from '../utils/string-utils'
import useResendSignupConfirmation from './useResendSignupConfirmation'

interface StateProps {
  loading: boolean
  error: null | (Error & { code: string })
  data: null | FetchUserAttributesOutput
}

type SignInProps = (props: { username: string; password: string }) => Promise<void>

export type useSignInReturnProps = StateProps & { signIn: SignInProps }

const useSignIn = (): useSignInReturnProps => {
  const [state, setState] = useState<StateProps>({
    loading: false,
    error: null,
    data: null,
  })
  // need to prevent updates to happen if the component is unMounted to avoir memory leaks.
  // https://www.debuggr.io/react-update-unmounted-component/
  const isMounted = useIsMounted()

  const { resendConfirmationCode } = useResendSignupConfirmation()
  const router = useRouter()

  const signIn = async ({
    username: usernameUnsanitized = '',
    password: passwordUnsanitized = '',
  }: {
    username?: string
    password?: string
  }) => {
    const username = sanitizeUsername(usernameUnsanitized)
    const password = sanitizePassword(passwordUnsanitized)
    setState({ loading: true, error: null, data: null })
    try {
      const signInRes = await signInFromAws({ username, password })
      if (!signInRes.isSignedIn && signInRes.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        const res = await resendConfirmationCode(username)
        if (res === false) {
          new Error('Error resending confirmation code. Please try again later.')
          setState({
            loading: false,
            error: Object.assign(new Error('Error resending confirmation code. Please try again later.'), {
              code: 'UserNotConfirmedException',
            }),
            data: null,
          })
        } else {
          router.push(
            `${unauthenticatedPaths.confirmUser}?email=${encodeURIComponent(usernameUnsanitized)}&userUnconfirmedError=true`,
          )
        }
      } else {
        const userAttributes = await fetchUserAttributes()
        analytics.identify(userAttributes?.email || 'unkown email', userAttributes)
        analytics.track('SignIn', userAttributes)
        if (isMounted.current) {
          setState({ loading: false, error: null, data: userAttributes })
          router.push(authenticatedPaths.workspaceHome)
        }
      }
    } catch (error: any) {
      if (isMounted.current) {
        setState({ loading: false, error, data: null })
      }
    }
  }

  return {
    ...state,
    signIn,
  }
}

export default useSignIn
