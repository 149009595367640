'use client'

import { Textfield } from '@pattern-library/src/basicUI/Textfield'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import useSignin from '../../../src/hooks/useSignin'
import { FormLayout } from '../_components/FormLayout'

type FormInput = {
  username: string
  password: string
}

export const Client: FC = () => {
  const { loading, data, error, signIn } = useSignin()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInput>({
    mode: 'onSubmit',
    defaultValues: {
      username: '',
      password: '',
    },
  })

  return (
    <FormLayout
      buttonLabel="login"
      handleSubmit={handleSubmit(signIn)}
      errorMessage={error ? error?.message : ''}
      formClassName="cy-login-page"
      submitting={!!(loading || !!data)}>
      <Controller
        name="username"
        control={control}
        rules={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Please enter a valid email address',
          },
        }}
        render={({ field }) => (
          <Textfield
            {...field}
            autocomplete="on"
            floatingLabel="* Email"
            placeholder="jon.doe@gmail.com"
            autoFocus={true}
            type="email"
            reactFormErrors={errors}
            fullWidth={true}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: 'Password is required',
        }}
        render={({ field }) => (
          <Textfield
            floatingLabel="*Password"
            placeholder="********"
            {...field}
            type="password"
            reactFormErrors={errors}
            fullWidth={true}
            autocomplete="on"
          />
        )}
      />
    </FormLayout>
  )
}
