import { resendSignUpCode } from 'aws-amplify/auth'
import useIsMounted from 'ismounted'
import { useContext, useState } from 'react'

import { snackbarContext } from '../utils/context-utils'
import { sanitizeUsername } from '../utils/string-utils'

type StateProps = {
  loading: boolean
  error: Error | null
}

type ResendConfirmationCodeProps = (usernameUnsanitized: string) => Promise<boolean>

const useResendSignupConfirmation = (): StateProps & { resendConfirmationCode: ResendConfirmationCodeProps } => {
  const [state, setState] = useState<StateProps>({
    loading: false,
    error: null,
  })
  // need to prevent updates to happen if the component is unMounted to avoir memory leaks.
  // https://www.debuggr.io/react-update-unmounted-component/
  const isMounted = useIsMounted()
  const openSnackbar = useContext(snackbarContext)

  //https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#re-send-confirmation-code
  const resendConfirmationCode: ResendConfirmationCodeProps = async usernameUnsanitized => {
    const username = sanitizeUsername(usernameUnsanitized)
    try {
      await resendSignUpCode({ username })
      if (isMounted.current && openSnackbar) {
        openSnackbar({
          message: 'Confirmation email resent successfully',
          type: 'neutral',
        })
        setState({ loading: false, error: null })
      }
      return true
    } catch (error: any) {
      if (isMounted.current && openSnackbar) {
        console.log('error resending code: ', error)
        setState({ loading: false, error })
        openSnackbar({
          message: 'An error occurred while resending the confirmation email, please try again later.',
          type: 'alert',
        })
      }
      return false
    }
  }

  return {
    ...state,
    resendConfirmationCode,
  }
}

export default useResendSignupConfirmation
